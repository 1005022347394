import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HeaderConfig } from '@core/models/header-config.model';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private _headerConfig: BehaviorSubject<HeaderConfig> = new BehaviorSubject<HeaderConfig>({
    mainHeader: '',
    backUrl: '',
  });

  public setHeaderConfig(headerConfig: HeaderConfig): void {
    this._headerConfig.next(headerConfig);
  }

  public getHeaderConfig(): Observable<HeaderConfig> {
    return this._headerConfig.asObservable();
  }
}
