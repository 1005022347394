import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HeaderService } from '@core/services/header.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class HeaderConfigResolver {
  constructor(
    private readonly headerConfigService: HeaderService,
    private readonly translationService: TranslateService,
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    let header = '';

    if (route?.data?.headerConfig?.mainHeader !== '') {
      header = this.translationService.instant(route.data.headerConfig.mainHeader);
    }

    this.headerConfigService.setHeaderConfig({
      mainHeader: header,
      backUrl: route?.data?.headerConfig?.backUrl,
    });
  }
}
