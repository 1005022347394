enum EntityRoutes {
  admin = 'admin',
  users = 'users',
  roles = 'roles',
  translation = 'translation',
  baseData = 'base-data',
  settings = 'settings',
  dashboard = 'dashboard',
  tenants = 'tenants',
  tenantsTheme = 'tenants-theme',
  tags = 'tags',
  builders = 'builders',
  acceptanceReports = 'acceptance-reports',
  groupReports = 'group-reports',
  reports = 'reports',
}

enum HelperRoutes {
  profile = 'profile',
  config = 'config',
}

enum GeneralRoutes {
  manage = 'manage',
  detail = 'detail',
  view = 'view',
  add = 'add',
  edit = 'edit',
}

enum AuthRoutes {
  auth = 'auth',
  login = 'login',
}

export type AppRoutes = typeof AppRoutes;
export const AppRoutes = {
  ...EntityRoutes,
  ...GeneralRoutes,
  ...HelperRoutes,
  ...AuthRoutes,
};
